<template>
  <div>
    <div class="memu-wrap">
      <span class="is-active">用户管理</span>
    </div>
    <el-card>
      <el-form inline>
        <el-form-item label="用户昵称:">
          <el-input v-model="form.nickName" size="small" />
        </el-form-item>
        <el-form-item label="手机号:">
          <el-input v-model="form.phone" size="small" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getDataList"
            >查 询</el-button
          >
          <el-button size="small" @click="resetForm">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="top-bar">
        <div class="left" />
        <div class="right">
          <el-button type="primary" size="mini" @click="onExport"
            >导出用户</el-button
          >
        </div>
      </div>
      <el-table
        id="rebateSetTable"
        v-loading="dataListLoading"
        :data="usersList"
        style="width: 100%; padding: 8px 0 !important"
        :header-cell-style="{ background: '#F7F7F7', color: '#1E1E1E' }"
        size="mini"
      >
        <el-table-column label="用户昵称" prop="nickName" />
        <el-table-column label="手机号" prop="phone">
          <template slot-scope="{ row }">
            <div>{{ row.phone | F_formatPhone }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column label="性别" prop="sex" /> -->
        <el-table-column label="注册时间" prop="createTime">
          <template slot-scope="{ row }">
            <div v-if="row.createTime">
              {{ row.createTime.substring(0, 10) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="捐款总金额" prop="alreadyCollectMoney" />
        <el-table-column label="捐款次数" prop="alreadyCollectCount" />
        <el-table-column label="点赞数" prop="likeNum" />
        <el-table-column label="分享数" prop="shareNum" />
        <el-table-column label="勋章数" prop="medalNum" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="openDetail(row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="size"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        style="padding: 20px; margin-bottom: 30px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <!-- 查看用户详情 -->
    <el-dialog :visible.sync="detailVisible" title="用户详情">
      <div v-if="itemData" class="detail-box">
        <el-form label-width="100px" label-position="left" size="small">
          <el-form-item label="用户昵称:">
            <span>{{ itemData.nickName }}</span>
          </el-form-item>
          <el-form-item label="手机号:">
            <span>{{ itemData.phone | F_formatPhone }}</span>
          </el-form-item>
          <!-- <el-form-item label="性别:">
            <span>{{ itemData.sex }}</span>
          </el-form-item> -->
          <el-form-item label="注册时间:">
            <span>{{ itemData.createTime.substring(0, 10) }}</span>
          </el-form-item>
          <el-form-item label="点赞数:">
            <span>{{ itemData.likeNum }}</span>
          </el-form-item>
          <el-form-item label="分享数:">
            <span>{{ itemData.shareNum }}</span>
          </el-form-item>
          <el-form-item label="个性签名:">
            <span>{{ itemData.signature }}</span>
          </el-form-item>
          <el-form-item label="获得勋章数:">
            <span>{{ itemData.medalNum }}</span>
            <el-button type="text" style="margin-left: 20px" @click="lookMedal"
              >查看</el-button
            >
          </el-form-item>
          <el-form-item label="捐款总金额:">
            <span>{{ itemData.alreadyCollectMoney }}</span>
          </el-form-item>
          <el-form-item label="捐款次数:">
            <span>{{ itemData.alreadyCollectCount }}</span>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="detailVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 查看勋章详情 -->
    <el-dialog
      :visible.sync="medalVisible"
      append-to-body
      title="勋章详情"
      width="50%"
    >
      <!-- <div>善行勋章</div>
      <div class="medal-box" v-if="itemData">
        <img v-for="i in itemData.medalNum" :key="i" src="@/assets/images/temporary/medal.jpg">
      </div> -->
      <div>
        成就勋章：<span
          v-if="itemData && itemData.medalNum == 0 && !itemData.medalNum"
          style="color: red"
          >暂无勋章</span
        >
      </div>
      <div v-if="itemData && itemData.medalNum" class="medal-box">
        <div v-for="item in medalsList" :key="item.id" class="medal-box-item">
          <img :src="item.picture" alt="" />
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="medalVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userInfoList, exportUser, getUserMedals } from '@/utils/api.js'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      usersList: [],
      form: {
        phone: '',
        nickName: ''
      },
      medalsList: [],
      current: 1,
      size: 12,
      total: null,
      dataListLoading: false,
      detailVisible: false,
      medalVisible: false,
      itemData: null
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async onExport() {
      const loading = this.$loading({
        lock: true,
        text: '用户信息列表文档生成中，请稍等！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const res = await exportUser()
      loading.close()
      const a = document.createElement('a')
      a.href = res
      a.click()
    },
    webExport() {
      // const res = await exportUser()
      /* generate workbook object from table */
      const wb = XLSX.utils.table_to_book(
        document.querySelector('#rebateSetTable')
      )
      /* get binary string as output */
      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array'
      })
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: 'application/octet-stream' }),
          '用户列表.xlsx'
        )
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout)
        }
      }
      return wbout
    },
    lookMedal() {
      this.medalVisible = true
      this.getUserMedals()
    },
    async getUserMedals() {
      const res = await getUserMedals({ userId: this.itemData.userId })
      if (res.success) {
        this.medalsList = res.data
      }
    },
    async getDataList() {
      this.dataListLoading = true
      const res = await userInfoList(this.form, this.current, this.size)
      this.dataListLoading = false
      console.log(res)
      if (res.code === 200) {
        this.usersList = res.data.records
        this.current = res.data.current
        this.total = res.data.total
      }
    },
    resetForm() {
      this.form = {}
      this.getDataList()
    },
    openDetail(row) {
      console.log(row)
      this.itemData = row
      this.detailVisible = true
    },
    // 每页数
    handleSizeChange(val) {
      this.size = val
      this.current = 1
      this.getDataList()
    },
    // 当前页
    handleCurrentChange(val) {
      this.current = val
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.memu-wrap {
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #000;
  span {
    margin-right: 20px;
    padding: 0 25px;
    line-height: 40px;
    display: block;
  }
  .is-active {
    background: rgba(18, 110, 255, 1);
    border-radius: 4px;
    color: #ffffff;
  }
}
.detail-box {
  padding: 0 5%;
}
.medal-box {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
}
.medal-box-item {
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dialog-footer {
  text-align: center;
}
</style>
